import {api} from '@/services/axios'

export const List  = {
  methods: {
    // API get all list object 
    async getAll() {
      try {
        const response = await api.get('getter/infos/list')
        return {
          status: 1,
          datas: response.data.datas
        }
      } catch (error) {
        return {
          status: 0,
          message: error.message
        }
      }
    },
    // Get all list item by key  
    async getListItem(key) {
      try {
        const response = await api.get(`getter/infos/list/${key}`)
        return {
          status: 1,
          datas: response.data.datas
        }
      } catch (error) {
        return {
          status: 0,
          message: error.message
        }
      }
    },
    // update list item by key, id and language 
    async editItem(key,id,lang, data) {
      try {
        const values_formatted =  {
          "value": data
        }
        const response = await api.put(`setter/list/${key}/${id}/${lang}`, values_formatted)
        return {
          status: 1,
          datas: response.data
        }
      } catch (error) {
        return {
          status: 0,
          message: error.message
        }
      }
    },
     // delete list item by key and id
     async deleteItem(key, id) {
      try {
        const response = await api.delete(`setter/list/${key}/${id}`)
        return {
          status: 1,
          datas: response.data.datas
        }
      } catch (error) {
        return {
          status: 0,
          message: error.message
        }
      }
    },
    async addItem(key,lang, value) {
      try {
        const values_formatted = {"values": [{
          "lang": lang,
          "value": value,

        }]}
        const response = await api.post(`setter/list/${key}`, values_formatted)
        return {
          status: 1,
          datas: response.data
        }
      } catch (error) {
        return {
          status: 0,
          message: error.message
        }
      }
    },
  } 
}

